import React, { useState } from "react";
import { FeaturesPageHeroPType } from "../../assets/Types/AcfGroupTypes/FeaturesPageHeroP.type";
import FeatureHeroVideo from "../../components/Videos/FeatureHeroVideo/FeatureHeroVideo";
import BlackCheckmark from "../../components/SVGIcons/BlackCheckmark/BlackCheckmark";
import Reviews from "../../components/Reviews/Reviews";
import { formInitialCtx } from "../../contexts/FormOnlyEmail.context";
import EmailOnlyFormProvider from "../../components/shared/EmailOnlyFormProvider";
import { EmailOnlyFormProps } from "../../components/shared/EmailOnlyForm/Email.type";
import { mxpPrimaryCtaClicked } from "../../utils/mixpanelEvents/mixpanelEvents";
import styles from "./FeaturesPageHeroPContainer.module.scss";
import { iterableSubmit } from "../../utils/iterableSubmit";
import { getFormattedTime } from "../../assets/util-scripts/helpers";
import { IterableDataType } from "@types";
import { useSignUpFormNavigate } from "@hooks/useSignUpFormNavigate";

const FeaturesPageHeroPContainer: React.FC<{ props: FeaturesPageHeroPType }> =
  ({
    props: {
      featuresPageHeroCcText,
      featuresPageHeroCtaText,
      featuresPageHeroEmailPlaceholder,
      featuresPageHeroSubcopy,
      featuresPageHeroTitle,
      featuresPageHeroVideoImage,
      wistiaId,
    },
  }) => {
    const [email, setEmail] = useState<string>("");
    const signUpFormNavigate = useSignUpFormNavigate();
    const submitEmailData: IterableDataType = {
      email: email,
      SIGN_UP_SOURCE: "Website - Sign Up CTA",
      startTrialFlowAt: getFormattedTime(),
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      setEmail(e.target.value);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      window.analytics.identify({
        account_status: "lead",
        email,
      });
      iterableSubmit(submitEmailData);
      mxpPrimaryCtaClicked("/sign-up/", ctx.buttonText, {
        email,
        withEmailField: true,
      });

      signUpFormNavigate(email);
    };

    const ctx: EmailOnlyFormProps = {
      ...formInitialCtx,
      email,
      buttonText: featuresPageHeroCtaText,
      placeholder: featuresPageHeroEmailPlaceholder,
      handleSubmit,
      handleChange,
      withArrow: true,
    };
    return (
      <section className={`full-width ${styles.featuresContainer}`}>
        <div className={`${styles.grid}`}>
          <div className={styles.textContainer}>
            <h1 className={styles.featuresPageHeroTitle}>
              {featuresPageHeroTitle}
            </h1>
            <p
              className={`hide-for-medium-only ${styles.featuresPageHeroSubcopy}`}
            >
              {featuresPageHeroSubcopy}
            </p>
            <section className={styles.emailContainer}>
              <EmailOnlyFormProvider ctxInitialVal={ctx} withDropdown />
            </section>
            <p className={styles.noCcReq}>
              <BlackCheckmark />
              <span
                dangerouslySetInnerHTML={{ __html: featuresPageHeroCcText }}
              />
            </p>
            <Reviews page={"Features"} className={styles.featuresReview} />
          </div>
          <FeatureHeroVideo
            className={styles.featuresPageHeroVideo}
            videoId={wistiaId}
            videoBackgroundImage={featuresPageHeroVideoImage}
          />
        </div>
      </section>
    );
  };

export default FeaturesPageHeroPContainer;
