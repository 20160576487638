import * as React from "react";
import { FeaturesAccordionPType } from "../../assets/Types/AcfGroupTypes/FeaturesAccordionP.type";
import FeaturesAccordionSectionRepeater from "../../components/Features/FeaturesAccordionSectionRepeater/FeaturesAccordionSectionRepeater";

const FeaturesAccordionPContainer: React.FC<{ props: FeaturesAccordionPType }> =
  ({ props: { featuresAccordionList } }) => (
    <FeaturesAccordionSectionRepeater
      featuresSections={featuresAccordionList}
    />
  );

export default FeaturesAccordionPContainer;
