import React, { useState } from "react";
import { AccordionSectionType } from "../../../assets/Types/AcfGroupTypes/FeaturesAccordionP.type";
import FeaturesAccordion from "../FeaturesAccordion/FeaturesAccordion";
import {
  setSessionStorageItem,
  getSessionStorageItem,
} from "../../../utils/SessionStorage";

import styles from "./FeaturesAccordionSection.module.scss";

const FeaturesAccordionSection: React.FC<{
  section: AccordionSectionType;
  colored: boolean;
}> = ({ section, colored }) => {
  const { anchorLinkId } = section;

  const storedAccordionIndex = JSON.parse(
    getSessionStorageItem(`accordion`) || null
  ) || {
    [anchorLinkId]: 0,
  };

  const [selectedAccordion, setSelectedAccordion] =
    useState(storedAccordionIndex);

  const saveOpenedAccordion = (index: number) => {
    const newSelectedAccordion = { [anchorLinkId]: index };

    setSessionStorageItem(`accordion`, JSON.stringify(newSelectedAccordion));
    setSelectedAccordion(newSelectedAccordion);
  };

  const sectionBackgroundColor = colored ? "#f8f8f8" : undefined;

  return (
    <section
      data-testid="section:FeaturesAccordionSection"
      id={section.anchorLinkId}
      className={styles.section}
      style={{
        backgroundColor: sectionBackgroundColor,
      }}
    >
      <div className={"full-width"}>
        <div className={styles.header}>
          <img src={section.icon.mediaItemUrl} alt={section.icon.altText} />
          <p
            className={`badge--text ${styles.sectionTitle}`}
            style={{ color: section.badgeColor, marginBottom: "32px" }}
          >
            {section.badge}
          </p>
          <h2>{section.title}</h2>
          <p className={`primary--grey-text ${styles.subcopy}`}>
            {section.subcopy}
          </p>
        </div>
        <FeaturesAccordion
          selectedAccordion={selectedAccordion[anchorLinkId]}
          setSelectedAccordion={saveOpenedAccordion}
          accordionItems={section.accordionList}
        />
      </div>
    </section>
  );
};

export default FeaturesAccordionSection;
