import * as React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { VideoProps } from "@types";
import VideoButton from "../../Buttons/VideoButton/VideoButton";

import styles from "../Video.module.scss";

const FeatureHeroVideo: React.FC<VideoProps> = ({
  videoId,
  videoBackgroundImage,
  className,
  buttonColor = "primary--red-bg",
}) => {
  const hoverImageStyles = videoId && styles.testimonialVideoContainer;

  return (
    <div
      data-testid="div:FeatureHeroVideo"
      className={`${hoverImageStyles} ${styles.featureHeroVIdeoContainer} ${className}`}
    >
      <GatsbyImage
        image={getImage(videoBackgroundImage.localFile)}
        alt={videoBackgroundImage.altText || ""}
      />
      {videoId && (
        <VideoButton
          buttonColor={buttonColor}
          iconWhite={true}
          isClass={false}
          videoID={videoId}
        />
      )}
    </div>
  );
};

export default FeatureHeroVideo;
