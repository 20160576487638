import * as React from "react";
import { AccordionSectionType } from "../../../assets/Types/AcfGroupTypes/FeaturesAccordionP.type";
import FeaturesAccordionSection from "../FeaturesAccordionSection/FeaturesAccordionSection";

const FeaturesAccordionSectionRepeater: React.FC<{
  featuresSections: AccordionSectionType[];
}> = ({ featuresSections }) => {
  const sectionsList = featuresSections.map((section, index) => (
    <FeaturesAccordionSection
      colored={index % 2 === 0}
      key={index}
      section={section}
    />
  ));

  return <div>{sectionsList}</div>;
};

export default FeaturesAccordionSectionRepeater;
