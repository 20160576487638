import * as React from "react";
import FeaturesPageGridList from "../../components/FeaturesPageGrid/FeaturesPageGridList/FeaturesPageGridList";
import FeatureGroupBanner from "../../components/FeatureGroupBanner/FeatureGroupBanner";

import { FeaturesPageGridPType } from "../../assets/Types/AcfGroupTypes/FeaturesPageGridP.type";
import { GroupModuleBannerCType } from "../../assets/Types/AcfGroupTypes/GroupModuleBannerC.type";
import styles from "./FeaturesPageGridPContainer.module.scss";

interface FeaturesPageGridPContainerProps {
  gridList: FeaturesPageGridPType;
  banner: GroupModuleBannerCType;
}
const FeaturesPageGridPContainer: React.FC<FeaturesPageGridPContainerProps> = ({
  gridList: { featuresPageGridList },
  banner,
}) => (
  <section
    className={`full-width pad-top--large pad-bottom--large ${styles.grid}`}
  >
    {banner?.addGroupBanner && <FeatureGroupBanner banner={banner} />}
    <FeaturesPageGridList featureGridList={featuresPageGridList} />
  </section>
);

export default FeaturesPageGridPContainer;
