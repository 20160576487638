import * as React from "react";
import { PageProps, graphql } from "gatsby";
import SEO from "gatsby-plugin-wpgraphql-seo";

import {
  ContentFaqsPType,
  FeatureCardsPType,
  FeaturesAccordionPType,
  FeaturesPageGridPType,
  FeaturesPageHeroPType,
  GroupModuleBannerCType,
  TestimonialSliderPType,
  YoastSEOType,
} from "@types";

import ContentFaqsPContainer from "../../containers/ContentFaqsPContainer/ContentFaqsPContainer";
import FeatureCardsPContainer from "../../containers/FeatureCardsPContainer/FeatureCardsPContainer";
import FeaturesAccordionPContainer from "../../containers/FeaturesAccordionPContainer/FeaturesAccordionPContainer";
import FeaturesPageGridPContainer from "../../containers/FeaturesPageGridPContainer/FeaturesPageGridPContainer";
import TestimonialSliderPContainer from "../../containers/TestimonialSliderPContainer/TestimonialSliderPContainer";
import FeaturesPageHeroPContainer from "../../containers/FeaturesPageHeroPContainer/FeaturesPageHeroPContainer";
import LayoutWithLargeFooterCta from "../../layouts/LayoutWithLargeFooterCta";
import { deIndexSeo } from "../../utils/deIndexSeo";
import { enableSmoothScroll } from "@utils/enableSmoothScroll";

type AllFeaturesType = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      contentFaqsP: ContentFaqsPType;
      featureCardsP: FeatureCardsPType;
      featuresAccordionP: FeaturesAccordionPType;
      featuresPageGridP: FeaturesPageGridPType;
      featuresPageHeroP: FeaturesPageHeroPType;
      groupModuleBannerC: GroupModuleBannerCType;
      testimonialSliderP: TestimonialSliderPType;
    };
    id: string;
  };
};

const AllFeaturesPage: React.FC<PageProps<AllFeaturesType>> = ({
  data,
  location,
}) => {
  enableSmoothScroll(true);
  const { wpPage } = data;
  const {
    template: {
      contentFaqsP,
      featureCardsP,
      featuresAccordionP,
      featuresPageGridP,
      featuresPageHeroP,
      groupModuleBannerC,
      testimonialSliderP,
    },
  } = wpPage;

  return (
    <LayoutWithLargeFooterCta pathname={location.pathname}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <FeaturesPageHeroPContainer props={featuresPageHeroP} />
      <FeaturesPageGridPContainer
        key={"var-0"}
        gridList={featuresPageGridP}
        banner={groupModuleBannerC}
      />
      <FeaturesAccordionPContainer props={featuresAccordionP} />
      <TestimonialSliderPContainer
        props={testimonialSliderP}
        hasCustomerSatisfaction
      />
      <FeatureCardsPContainer props={featureCardsP} threeColumns />
      <ContentFaqsPContainer
        contentFaqsList={contentFaqsP.contentFaqsList}
        contentFaqsSubcopy={contentFaqsP.contentFaqsSubcopy}
        contentFaqsTitle={contentFaqsP.contentFaqsTitle}
        sectionBackgroundColor={"grey--weak-bg"}
      />
    </LayoutWithLargeFooterCta>
  );
};

export const query = graphql`
  query AllFeaturesTemplateQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpAllFeaturesTemplate {
          contentFaqsP {
            contentFaqsList {
              ... on WpFaq {
                faqsC {
                  questionsAnswer
                }
                title
              }
            }
            contentFaqsSubcopy
            contentFaqsTitle
          }
          featureCardsP {
            featuresCardsCta
            featuresCardsCtaText
            featuresCardsList {
              featuresCardsItemIcon {
                altText
                mediaItemUrl
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [WEBP, JPG, AUTO]
                    )
                  }
                }
              }
              featuresCardsItemBodycopy
              featuresCardsItemTitle
            }
            featuresCardsSubcopy
            featuresCardsTitle
          }
          featuresAccordionP {
            featuresAccordionList {
              accordionList {
                accordionContent
                accordionTitle
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 800
                        placeholder: BLURRED
                        formats: [WEBP, JPG, AUTO]
                      )
                    }
                  }
                }
              }
              anchorLinkId
              badge
              badgeColor
              icon {
                altText
                mediaItemUrl
                localFile {
                  publicURL
                }
              }
              subcopy
              title
            }
          }
          featuresPageGridP {
            featuresPageGridList {
              image {
                altText
                mediaItemUrl
              }
              link
              microcopy
              title
            }
          }
          featuresPageHeroP {
            featuresPageHeroBadge
            featuresPageHeroCcText
            featuresPageHeroCtaLink
            featuresPageHeroCtaText
            featuresPageHeroEmailPlaceholder
            featuresPageHeroSubcopy
            featuresPageHeroTitle
            featuresPageHeroVersion
            featuresPageHeroVideoImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                    formats: [WEBP, JPG, AUTO]
                  )
                }
              }
            }
            featuresPageHeroVideoLink
            featuresPageHeroVideoLinkText
            footerCopy
            microcopy
            wistiaId
            benefitsList {
              benefit
            }
          }
          groupModuleBannerC {
            addGroupBanner
            copy
            ctaText
            ctaUrl
            iconImage {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [WEBP, JPG, AUTO]
                    width: 300
                    placeholder: BLURRED
                  )
                }
                publicURL
              }
            }
          }
          testimonialSliderP {
            testimonialHeadline
            testimonialSubcopy
            testimonialModuleRelationship {
              ... on WpTestimonial {
                testimonialsC {
                  testimonialWistiaId
                  testimonialsAuthor
                  testimonialsLabel
                  testimonialsLocation
                  testimonialsQuote
                  testimonialVideoBackgroundImage {
                    altText
                    mediaItemUrl
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          placeholder: BLURRED
                          formats: [WEBP, JPG, AUTO]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AllFeaturesPage;
